// fonts
// Silkscreen 400 700, Martian Mono 400 700, Inter 500 700, Monoton 400
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;700&family=Martian+Mono:wght@400;700&family=Monoton&family=Silkscreen:wght@400;700&display=swap');

// colors
$white: #FFFFFF;
$red: #FF0000;
$red-text: #d80032;
$black-text: #001122;
$light-blue: #B3DDF2;
$dark-grey: #001122;
$dark-grey-blue: #2b2d42;
$light-grey-bg: #EDF2F4;

body, html {
  overflow-x: hidden;
}

.App {
  padding-bottom: 35px;
  h1, h2, h3, h4, h5, h6 { margin: 0 }

  .row {
    display: grid;
    justify-content: center;
    > * {
      max-width: 1000px;
    }
  }

  .sticky-header {
    position: fixed;
    top: 0;
    width: 100%;
    background: white;
    box-shadow: 0 0.7065919984px 0.7065919984px -0.625px rgba(0, 0, 0, 0.15), 0px 1.8065619053px 1.8065619053px -1.25px rgba(0, 0, 0, 0.14398), 0px 13.6467614115px 13.6467614115px -3.125px rgba(0, 0, 0, 0.10451), 0px 30px 30px -3.75px rgba(0, 0, 0, 0.05);
  }

  header {
    background-color: $white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 26px;
    width: 90vw;

    .wrapper-logo {
      display: flex;
      cursor: pointer;
      align-items: center;
      gap: 4px;
      > img {
        width: 30px;
      }
    }
  }

  main {
    background-color: $light-blue;
    text-align: center;
    padding-bottom: 20px;

    @media only screen and (max-width: 1200px) {
      padding-top: 80px;
      padding-bottom: 120px;
    }


    > h1.logo {
      padding-top: 60px;
      font-size: min(14vw, 180px)
    }

    > .subtitle {
      font-family: Silkscreen, sans-serif;
      font-weight: 400;
      padding-top: 20px;
      padding-right: 20px;
      padding-left: 20px;
      color: $white;
      letter-spacing: -2.6px;
      @extend .text-xl;
    }

    .buttons-wrapper {
      display: flex;
      flex-direction: row;
      gap: 12px;
      padding: 30px;
      justify-content: center;
    }

    @media only screen and (max-width: 800px) { // Mobile
      > h1.logo {
        font-size: max(18vw, 95px);
      }
    }
  }

  .logo {
    color: $red;
    font-family: Monoton, monospace;
    font-weight: 400;
  }

  .text-big {
    font-size: 80px;
  }
  .text-xl {
    font-size: 48px;
  }
  .text-lg {
    font-size: 32px;
  }
  .text-md {
    font-size: 24px;
  }
  .text-xs {
    font-size: 16px;
  }

  div.section-one {
    background-color: $dark-grey;
    color: white;
    padding: 70px 45px;

    .section-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      >.left {
        flex: 0 0 400px; /* Left column is fixed at 450px width */
        padding-right: 45px;
      }
      >.right {
        flex: 1 0 0;
      }


    }
    @media only screen and (max-width: 950px) { // Tablet + Mobile
      padding: 45px 30px;
      .section-wrapper {
        gap: 25px;
        >.left {
          flex: 1 1 auto;
          padding-bottom: 35px;
          padding-right: 0;
          > .title {
            text-align: center;
          }
        }
        >.right {
          flex: 1 1 auto;
        }
      }
    }

    h1.title {
      @extend .text-xl;
      font-family: Monoton, monospace;
      font-weight: 400;
      line-height: 1;
      text-align: left;
      word-spacing: 18px;
    }

    h2.text {
      @extend .text-md;
      font-family: Silkscreen, sans-serif;
      font-weight: 400;
      line-height: 28px;
      text-align: left;
      margin-bottom: 20px;
    }

  }

  div.section-two {
    background-color: $light-grey-bg;
  }

  footer {
    text-align: center;
    color: $dark-grey-blue;

    h1 {
      color: $black-text;
      font-size: 50px;
      font-family: Silkscreen, sans-serif;
      font-weight: 700;
      letter-spacing: -2.1px;
      margin-top: 80px;
      margin-bottom: 40px;
    }
  }

  img {
    border-radius: 20px;
    height: 100%;
  }

  .footer-svgs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;

    a {
      color: $dark-grey-blue;
      transition: all 1s;
      :hover {
        filter: brightness(1.1);
      }
    }
  }
}
