@import "/src/App";

.btn {
  border-radius: 8px;
  cursor: pointer;

  padding: 12px 18px;
  outline: none;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  transition: all 0.6s;

  &.btn-primary {
    background: $dark-grey;
    box-shadow: rgba(0, 0, 0, 0.15) 0 0.706592px 0.706592px -0.625px, rgba(0, 0, 0, 0.145) 0px 1.80656px 1.80656px -1.25px, rgba(0, 0, 0, 0.137) 0px 3.62176px 3.62176px -1.875px, rgba(0, 0, 0, 0.125) 0px 6.8656px 6.8656px -2.5px, rgba(0, 0, 0, 0.106) 0px 13.6468px 13.6468px -3.125px, rgba(0, 0, 0, 0.05) 0px 30px 30px -3.75px;
    color: $white;
    &:hover {
      background: $white;
      color: $dark-grey-blue;
    }
  }
  &.btn-secondary {
    background: $white;
    color: $dark-grey-blue;
    &:hover {
      background: $red;
      color: $light-blue
    }
  }
  &.btn-member {
    font-family: "Martian Mono", monospace;
    font-size: 16px;
    text-transform: uppercase;
    color: $dark-grey-blue;
    background: $light-grey-bg;
    width: min(80vw, 350px);

    margin: 50px auto 20px;

    &:hover {
      background: $light-blue;
      color: $white;
    }
  }
  &.btn-header {
    box-shadow: 0 0.7065919983928324px 0.7065919983928324px -0.625px rgba(0, 0, 0, 0.15), 0px 1.8065619053231785px 1.8065619053231785px -1.25px rgba(0, 0, 0, 0.14398), 0px 3.6217592146567767px 3.6217592146567767px -1.875px rgba(0, 0, 0, 0.13793), 0px 6.8655999097303715px 6.8655999097303715px -2.5px rgba(0, 0, 0, 0.12711), 0px 13.646761411524492px 13.646761411524492px -3.125px rgba(0, 0, 0, 0.10451), 0px 30px 30px -3.75px rgba(0, 0, 0, 0.05);
    font-weight: 400;
    font-style: normal;
    font-family: "Silkscreen", sans-serif;
    font-size: 14px;
    color: $red;
    background: $light-blue;

    &:hover {
      filter: brightness(1.1);
    }
  }
}

[data-reset="button"] {
  border-width: 0;
  padding: 0;
  background: none;
}
